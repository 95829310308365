<template>
  <v-container>
    <div class="text-h5 text-md-h2 font-weight-bold text-center mt-16 primary--text">
      What's next?
      <div class="pp-hr pp-center secondary mt-md-8"></div>
      <div class="text-h6 my-7 secondary--text">
        <p>
          Your online course materials will be ready shortly in your LearningPlus Portal
        </p>

        <p>
          If they are not showing after 30 minutes please contact
          <a :href="supportEmail.href"
             :title="supportEmail.title"
             :aria-labelledby="supportEmail.linkText"
             @click="matomoEvent('Contact Support', 'Contact Support Click', 'Contact Support Email');"
          >
            {{ supportEmail.email }}
          </a>.
        </p>

        <p class="text-subtitle-2 px-lg-16">
          Our support desk is monitored from 9:00 am to 5:00 pm, Monday to Friday.<br/>
          Queries will be answered within these times, if your query is submitted outside of our core support hours, we will reply as soon as we can.
        </p>
      </div>
    </div>

    <v-row class="ml-md-16 mt-10 px-xl-16">

      <v-col sm="12" md="6">
        <v-btn
            title="Continue to LearningPlus Portal"
            aria-labelledby="Continue to LearningPlus Portal"
            large
            rounded
            height="400"
            width="95%"
            class="pp-scale-1 pp-portal-btn mt-md-16 pa-xl-10 mb-5"
            elevation="10"
            :href="path"
            target="_blank"
            rel="noopener noreferrer"
            @click="matomoEvent('Navigate Page', 'Navigate Page Click', 'LearningPlus Portal');"
        >
          <v-img src="/images/logo/logo-3.svg"
                 alt="LearningPlus Logo"
                 width="300"
                 contain
                 class="rounded-xl"
          />
        </v-btn>
      </v-col>

      <v-col sm="12" md="5">
        <div class="text-h6 font-weight-bold">
          Tips on How to Learn Best
        </div>

        <div class="pp-hr"></div>

        <div class="font-weight-black primary--text">
          01.
        </div>

        <div class="pr-16">
          Think about what types of information help you best grasp new concepts and employ relevant study strategies.
        </div>

        <div class="pp-hr"></div>

        <div class="font-weight-black primary--text">
          02.
        </div>

        <div class="pr-16">
          Start a positive habit by setting aside time and space in your day or week to study.
        </div>

        <div class="pp-hr"></div>

        <div class="font-weight-black primary--text">
          03.
        </div>

        <div class="pr-16">
          If you need clarification, communicate with us to get the full value of the education. Our tutors are
          reachable through multiple
          <a @click="navigateContact()">
            channels and social medias
          </a>
          to answer your questions.
        </div>
      </v-col>
    </v-row>

    <v-divider class="mt-16"/>
  </v-container>
</template>

<script>
import router from "@/router";
import {mapGetters} from "vuex";

export default {
  name: "Redirect",
  methods: {
    navigateContact() {
      router.push({path: '/#contact'}).catch(err => {
        if (err.name === 'NavigationDuplicated') {
          window.location.href = '#contact';
        }
      });
    },
  },
  computed: {
    ...mapGetters(['supportEmail']),
  },
  data() {
    return {
      path: process.env.VUE_APP_LEARNER_PORTAL,
    };
  },

};
</script>

<style scoped>
.pp-portal-btn {
  background-image: url("/images/categories/pp-default.jpg");
  background-position: center;
  background-size: cover;
}
</style>
