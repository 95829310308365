<template>
  <div v-if="$auth.isAuthenticated">
    <title>Thank you for choosing LearningPlus</title>
    <v-container fluid>
      <Banner></Banner>
      <Redirect></Redirect>
    </v-container>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Banner from "@/components/success/Banner";
import Redirect from "@/components/success/Redirect";
import {matomoEcommerce} from "@/mixin/matomoEcommerce";

export default {
  name: "CheckoutSuccess",
  metaInfo: {
    title: 'Purchase successful',
    meta:[
      { description: 'Thank you for choosing LearningPlus' },
    ],
  },
  components: {Redirect, Banner},
  mixins: [matomoEcommerce],
  computed: {
    ...mapGetters(['basket', 'basketValue', 'stripePaymentIntentId']),
  },
  methods:{
    ...mapActions(['checkoutRegisterSuccess']),
  },
  async mounted() {
    this.matomoTrackEcommerceOrder(this.stripePaymentIntentId, this.basketValue);
    await this.checkoutRegisterSuccess(this.$route.query.id);
  },
};
</script>
